#policiesPage{
    min-height: 63vh;
    /* background: linear-gradient(176deg, var(--c1) 0%, var(--c2) 40%, var(--c4) 110%); */
    background: var(--c1);
}

#policiesPage .sectionTitle{
    Color: var(--c5);
    padding: 1rem;
    height: 70vh;
    background-image:  url('../../images/K0005_12.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

#policiesPage h1, #policiesPage p{
    margin: 0;
    padding: 1rem;
}

#policiesPage h2{
    font-size: clamp(2rem, 4vw, 4rem);
    margin: 1rem 5rem;
}

#policiesPage .innerText{
    position: absolute;
    width: 70%;
    min-height: 15vh;
    right: 10%;
    top: 60vh;
    text-align: justify;
    background: linear-gradient(176deg, rgba(236,224,209,0.8) 0%, rgba(219,193,172,0.9) 40%, var(--c4) 110%);
    color: var(--c5);
    padding: 3%;
}


#policiesPage .innerText p{
    font-size: clamp(1rem, 2vw, 2rem);
    line-height: 2.5rem;
}

#firstHeader{
    margin-bottom: 4rem;
}


#policiesPage .section{
    color: var(--c4);
    box-sizing: border-box;
    position: relative;
    min-height: 10vh;
    padding-bottom: 5vh;
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* #policiesPage  > .section {
    margin-top: 10vh;
} */

#policiesPage  > .section ~ .section {
    margin-top: 0vh;
}

/* #policiesPage :nth-child(5){
    margin-top: 10vh;
} */

#policiesPage .section::before{
    content: "";
    position: absolute;
    left: 15%;
    bottom: 0;
    height: 0.2vh;
    width: 70%;
    background-color: rgba(56,34,15,0.5);
}

#policiesPage .lastSection::before{
    bottom: 3vh;
}

#policiesPage .section h1{
    font-size: clamp(2rem, 4vw, 4rem);
    color: var(--c5);
    align-self: flex-start;
    margin-left: 3%;
}

#policiesPage .section header{
    display: flex;
    align-self: flex-start;
    width: 80%;
}

#policiesPage .section p{
    color: var(--c4);
    width: 85%;
    /* padding: 5rem 5rem 1rem 5rem; */
    text-align: justify;
    font-size: clamp(1rem, 1.3vw, 1.5rem);
    line-height: 1.5rem;
}

#policiesPage .section h2{
    font-size: clamp(1rem, 2vw, 2rem);
    align-self: flex-start;
    margin-left: 6%;
    /* border-bottom: 1px solid rgba(56,34,15,0.4); */
}

   /* hyperlink */

#policiesPage a{
    text-decoration: none;
    color: var(--c5);
}
   /* button */

#policiesPage .section .btn{
    max-width: 15vw;
  }

  #policiesPage .section .btn a{
    font-size: clamp(1rem, calc(0.8vw + 0.8vh), 1.5rem);
    color: var(--c5);
    text-decoration: none;
    transition: all 1s;
  }
  
  #policiesPage .section .btn:hover a{
    cursor: pointer;
    color: var(--c2);
  }
  
  #policiesPage .section .btn:hover::before {
    width: 100%;
  }
  
  #policiesPage .section .btn::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color:  rgba(99,72,50,1);
    transition: 0.3s ease;
    z-index: -1;
  }

  #policiesPage .section .btn:hover::after {
    height: 100%;
    transition-delay: 0.3s;
    border-radius: 3px 3px 0 0;
  }


/* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){
    #policiesPage .AUCardMainContainer .AUCardContainer .AUCard .actButton{
        margin-left: -2vw;
    }
  }

/* Desktop */
@media screen and (min-width:1023px) and (max-width: 3000px) {
    #policiesPage .AUCardMainContainer .AUCardContainer .AUCard .actButton{
        margin-left: -4vw;
    }
  }

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1023px) {
    #policiesPage .innerText{
        top: 65vh;
        min-height: 0;
        height: 20vh;
        padding: 1.5%;
    }

    #policiesPage .innerText p{
        line-height: 1.5rem;
  }

}


/* mobile */
@media screen and (max-width: 464px) {

    #policiesPage .innerText p, #policiesPage .section p{
        line-height: 1.5rem;
    }

    #policiesPage .section h1{
        margin-left: 0rem;
    }

    #policiesPage .section h2{
        margin-left: 2rem;
    }
    
    #policiesPage .AUCardMainContainer h1{
            align-self: flex-start;
         }

    #policiesPage .AUCardMainContainer .AUCardContainer .AUCard .actButton{
        margin-left: 0;
    }   

         #policiesPage .AUCardMainContainer .AUCardContainer .AUCard .btn {
        max-width: 80vw;
        width: 60vw;
    }

    #policiesPage .innerText{
        position: relative;
        /* top: 400px; */
        right: -12%;
        top: -70px;

    }

    #policiesPage h1{
        font-size: 3.5rem;
    }
    
    #policiesPage h2{
        font-size: clamp(2rem, 4vw, 4rem);
        margin: 1rem 1rem;
    }

    #policiesPage .section header{
        width: 95%;
    }

    #firstHeader{
        margin-bottom: -2rem;
    }

  }