#cartPageContainer{
    min-height: 70vh;
    /* background: linear-gradient(176deg, var(--c1) 0%, var(--c2) 40%, var(--c4) 110%); */
     background: var(--c1);
     scrollbar-color: var(--c4) rgba(219,193,172,0);
     scrollbar-width: thin;
     
}

#cartPageContainer .sectionTitle{
    Color: var(--c5);
    padding: 1rem;
    height: 10vh;
}

#cartPageContainer .sectionTitle h1{
    padding: 0 0 0 2rem;
    margin: 0;
    font-size: clamp(2rem, 7vw, 5rem);
}

#cartPageContainer #cartContent{
    width: 90%;
    margin: 5% 5% 0% 5%;
    display: flex;
    flex-direction: column; /*alignement en colonne des cart item row*/
    align-items: center;
}

#cartPageContainer #cartContent h2{
    color: var(--c4);
}

#cartPageContainer #cartContent p{
    color: var(--c4);
    font-size: calc(0.9vw + 0.9vh);
    margin-bottom: 2rem;
}

#cartPageContainer #cartContent .cartItemRow{
    border-top: 1px solid var(--c5);
    width: 100%;
    height: 230px;
    padding: 1rem;
    display: flex;
    flex-direction: row; /*alignement en colonne des cart item row*/
    justify-content: flex-start;
    margin-bottom: 1.5rem;
}

#cartPageContainer #cartContent .cartItemRow .cartImgContainer {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--c5);

}

#cartPageContainer #cartContent .cartItemRow .cartImgContainer .cartImg {
    width: 100%;
    height: 80%;
    object-fit: contain;
}

#cartPageContainer #cartContent .cartItemRow .cartImgContainer p {
    font-size: calc(0.9vw + 0.9vh);
    margin-top: 1rem;
}


#cartPageContainer #cartContent .cartItemRow .cartDesc{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    overflow: auto;
}

#cartPageContainer #cartContent .cartItemRow ul{
    width: 95%;
    height: 70%;
    color: var(--c5);
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 1rem;
    font-weight: bold;
    margin: 0 0 0 1rem;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

#cartPageContainer #cartContent .cartItemRow ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    transition: all 0.5s;
    font-size: calc(0.6vw + 0.6vh);
    padding: 0.1rem;
    width: 48%;
}

#cartPageContainer #cartContent .cartItemRow ul li img{
    height: 1.5rem;
    margin-right: 1rem;
}

#cartPageContainer #cartContent .cartItemRow .customizationDetails{
    max-height: 25%;
    width: 95%;
    font-size: calc(0.6vw + 0.6vh);
    text-align: left;
    color: var(--c5);
}

#cartPageContainer #cartContent .cartItemRow .customizationDetails .customizationDetailsBold{
    font-weight: bold;
}


#cartPageContainer #cartContent .cartItemRow .cartPrice{
    height: 16vh;
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#cartPageContainer #cartContent .cartItemRow .cartPrice .priceContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: var(--c5);
}

#cartPageContainer #cartContent .cartItemRow .cartPrice img{
    height: 2rem;
    margin-bottom: 0.3rem;
    margin-right: 0.5rem;
    
}

#cartPageContainer #cartContent .cartItemRow .cartPrice img:hover{
    height: 2.5rem;
    transition: all 0.5s;
    cursor: pointer;
}

#cartPageContainer #cartContent .cartItemRow .cartPrice p{
    margin: 0;
    font-size: calc(0.8vw + 0.8vh);
}

#cartPageContainer #cartContent .subTotalRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid var(--c5);
    font-size: calc(1.2vw + 1.2vh);
}

#cartPageContainer #cartContent .subTotalRow p {
    padding: 1rem;
    color: var(--c5);
    
}

#cartPageContainer .checkoutBtn{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 3rem 3rem 0;
}

#cartPageContainer .checkoutBtn .componentOptionButton{
    background-color: var(--c2);
    font-size: calc(1vw + 1vh);
    padding: 1.5rem;
    height: 4rem;
    /* border-radius: 4px; */
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.5s ease;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    margin: 1rem 1rem;
    padding: 0.5rem;
    height: 3rem;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
}


#cartPageContainer .checkoutBtn .componentOptionButton:before {
    content: "";
    position: absolute;
    left: -6px;
    bottom: 0;
    height: 0.5vh;
    width: 0;
    background-color: var(--c2);
    transition: 0.2s ease;
  }
  
  #cartPageContainer .checkoutBtn .componentOptionButton:hover {
    color: var(--c1);
    transition-delay: 0.2s;
    background: var(--c4)
  }
  
  #cartPageContainer .checkoutBtn .componentOptionButton:hover::before {
    width: 95%;
  }

  
  #cartPageContainer .checkoutBtn .componentOptionButton::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color:  var(--c5);
    transition: 0.3s ease;
    z-index: -1;
  }

  #cartPageContainer .checkoutBtn  .componentOptionSelected::after{
    height: 100%;
    transition-delay: 0.3s;
    border-radius: 3px 3px 0 0;
  }

/* =========== shipping section ============= */
.shippingForm{
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;
}
.shippingForm .shippingInfoSectionContainer,
.shippingForm .shippingPricesSectionContainer{
    background-color: var(--c1);
    flex: 1 0 50%;
    border-radius: 1%;
    padding-bottom: 1rem;
    margin: 1rem 2rem 1rem 0;
    -webkit-box-shadow: 0px 4px 8px 2px var(--c2);
    -moz-box-shadow: 0px 4px 8px 2px var(--c2);
    box-shadow: 0px 4px 8px 4px var(--c2);
    color: var(--c5);

}

.shippingForm .shippingInfoSectionContainer h1,
.shippingForm .shippingPricesSectionContainer h1{
    margin: 1rem 0 3rem 1rem;
    font-size: clamp(1rem, 2vw, 2rem);
}

.shippingForm .shippingInfoSectionContainer form{
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.shippingForm .shippingInfoSectionContainer form label{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 0 50%;
    /* min-width: 30vw; */
    background-color: var(--c1);
    color: var(--c5);
    font-size: clamp(1rem, 1vw, 1rem);
    min-height: 80px;
}

.shippingForm .shippingInfoSectionContainer form mapbox-address-autofill{
    position: relative;
    display: flex;
    flex: 1 0 50%;
    background-color: var(--c1);
    color: var(--c5);
    font-size: clamp(1rem, 1vw, 1rem);
}

.shippingForm .shippingInfoSectionContainer form input{
    width: 90%;
    background-color: var(--c1);
    outline: 0;
    border: none;
    border-bottom: 1px solid rgb(56,34,15,0.4);
    font-size: clamp(1rem, 1vw, 1rem);
    margin: 0.6rem 0 1rem 0;
    /* color: rgb(56,34,15,0.8); */
    color: var(--c4);
}

.shippingForm .shippingInfoSectionContainer form label input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px var(--c1) inset; /* Change the color to your own background color */
    -webkit-text-fill-color: var(--c4)
}

.shippingForm .shippingInfoSectionContainer form label input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px var(--c1) inset;/*your box-shadow*/
    -webkit-text-fill-color: var(--c4)
} 

/* =========== Shipping price radio buttons ============= */


.shippingForm .shippingPricesSectionContainer .radio-input input {
    display: none;
  }
  
  .shippingForm .shippingPricesSectionContainer .radio-input label {
    border: 1px solid var(--c5);
    border-radius: 6px;
    width: 90%;
    margin: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    align-items: center;
  }
  
.shippingForm .shippingPricesSectionContainer .radio-input input:checked + label {
    border-color: var(--c5);
    border-width: 2px;
  }
.shippingForm .shippingPricesSectionContainer .radio-input label:hover {
    border-color: var(--c5);
  }
  
.shippingForm .shippingPricesSectionContainer .radio-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  
.shippingForm .shippingPricesSectionContainer .circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--c3);
    margin-right: 0.5rem;
    position: relative;
  }
  
.shippingForm .shippingPricesSectionContainer .radio-input input:checked + label div.circle::before {
    content: "";
    display: inline;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--c5);
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

.shippingForm .shippingPricesSectionContainer .text {
    display: flex;
    align-items: center;
    font-size: clamp(1rem, 1vw, 1rem);
    width: 30%;
  }
  
.shippingForm .shippingPricesSectionContainer .price {
    display: flex;
    flex-direction: column;
    text-align: flex-start;
    font-weight: bold;
    width: 70%;
    font-size: clamp(1rem, 1vw, 1rem);
  }
  
.shippingForm .shippingPricesSectionContainer .small {
    font-size: 12px;
    color: rgb(136, 138, 139);
    font-weight: 100;
    padding-top: 2px;
  }
  
.shippingForm .shippingPricesSectionContainer p {
    width: 90%;
    text-align: justify;
  }
  
.shippingForm .shippingPricesSectionContainer .info {
    position: absolute;
    display: inline-block;
    font-size: 11px;
    /* background-color: rgb(31, 236, 123); */
    background-color: var(--c3);
    color: var(--c1);
    font-weight: bold;
    border-radius: 5px;
    padding: 1px 9px;
    top: 0;
    transform: translateY(-50%);
    right: 5px;
  }

  

/* ======== Loader ========== */
 .loader {
    background: var(--c2);
  }

.loaderActive {
    background: 
      linear-gradient(90deg,var(--c2) 33%, var(--c3) 50%,var(--c2) 66%)
      var(--c2);
    background-size:300% 100%;
    animation: l1 4s infinite linear;
  }
  @keyframes l1 {
    0% {background-position: right}
  }

  .loader .show{
    display: block;
  }

  .loader .hide{
    display: none;
  }


  /* ===============  Cart Return and Missing Item ============*/

.cartMIR{
    display: flex;
    flex-direction: column;
    color: var(--c4);
}

.cartMIR h1{
    margin: 10vh 0 2vh 2vw;
    font-size: 4rem;
}

.cartMIR p, .cartMIR ul{
    margin: 2vh 5vw;
    font-size: clamp(1rem, 1.6vw, 2rem);
    text-align: justify;
}

.cartMIR ul{
    list-style-type: none;
}

.cartMIR ul li{
    padding: 0.4rem 0rem;
}

.btnContainerRow {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
}

.cartMIR label{
    display: block;
    margin: 2vh 8vw;
    min-width: 50vw;
}

.cartMIR input{
    width: 50%;
    background-color: var(--c1);
    outline: 0;
    border: none;
    border-bottom: 1px solid rgb(56,34,15,0.4);
    font-size: clamp(1rem, 1vw, 1rem);
    margin: 0.6rem 0 1rem 8vw;
    color: var(--c4);
}


.cartMIR label input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px var(--c1) inset; 
    -webkit-text-fill-color: var(--c4)
}

.cartMIR label input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px var(--c1) inset;
    -webkit-text-fill-color: var(--c4) 
}



/* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){

  }

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {

  }

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1024px) {

    #cartPageContainer #cartContent{
        margin: 5% 2% 0% 2%;
        width: 95%;
    }

    #cartPageContainer #cartContent .cartItemRow ul li{
        font-size: 0.9rem;
    }

    #cartPageContainer #cartContent .cartItemRow{
        min-height: 300px;
        flex-wrap: wrap;
        margin-bottom: 1rem 0;
        padding: 1rem 1rem 0 1rem;
        
    }

    #cartPageContainer #cartContent .cartItemRow ul {
        flex-wrap: nowrap;
        height: 90%;
    }

    #cartPageContainer #cartContent .cartItemRow ul li{
        width: 95%;
    }

    #cartPageContainer #cartContent .cartItemRow .cartImgContainer {
        width: 40%;
    }


    #cartPageContainer #cartContent .cartItemRow .cartImgContainer p {
        font-size: 1.2rem;
        margin-top: 1rem;
    }

    #cartPageContainer #cartContent .cartItemRow .cartDesc{
        justify-content: space-around;
        width: 50%;
     }

    #cartPageContainer #cartContent .cartItemRow .cartPrice{
       align-items: flex-end;
       justify-content: space-between;
       height: 100%;
    }

    #cartPageContainer #cartContent .cartItemRow .customizationDetails{
        font-size: 1rem;
        margin-left: 2em;
        width: 90%;
    }

    #cartPageContainer #cartContent .cartItemRow .cartPrice p{
        font-size: 1rem;
    }

    #cartPageContainer #cartContent .subTotalRow p {
        font-size: 1rem;
    }

    .shippingForm{
        flex-direction: column;
    }

    .shippingForm .shippingInfoSectionContainer,
    .shippingForm .shippingPricesSectionContainer{
    margin: 1rem 0;
    }

  }


/* mobile */
@media screen and (max-width: 464px) {

    #cartPageContainer {
        min-height: 110vh;
    }

    #cartPageContainer .sectionTitle{
        margin: 0;
        padding: 1rem 0;
        height: 6rem;
    }

    #cartPageContainer .sectionTitle h1{
       font-size: 4rem;
    }

    #cartPageContainer #cartContent {
        margin: 1%;
        width: 97%;
    }

    #cartPageContainer #cartContent h2{
        margin: 0 0 1rem 0;
        font-size: 2rem;
        margin-top: 3rem;
        border-top: 1px solid var(--c5);
        border-bottom: 1px solid var(--c5);
        width: 70%;
        text-align: center;
    
    }

    #cartPageContainer #cartContent p{
        font-size: calc(1.2vw + 1.2vh);
    }

    #cartPageContainer #cartContent .cartItemRow{
        min-height: 700px;
        flex-wrap: wrap;
    }

    #cartPageContainer #cartContent .cartItemRow .cartImgContainer {
        width: 100%;
        height: 35%;
    }

    #cartPageContainer #cartContent .cartItemRow .cartImgContainer p{
        margin-bottom: 0;
        padding-bottom: 0;
    }

    #cartPageContainer #cartContent .cartItemRow .cartDesc{
        width: 100%;
        height: 50%;
        margin-top: 0.5rem
    }

    #cartPageContainer #cartContent .cartItemRow .cartDesc ul, 
    #cartPageContainer #cartContent .cartItemRow .cartDesc .customizationDetails{
        margin-left: 0;
    }

    #cartPageContainer #cartContent .cartItemRow ul li{
    width: 100%;
}

    #cartPageContainer #cartContent .cartItemRow .cartImgContainer p {
        font-size: calc(1vw + 1vh);
        margin-top: 1rem;
        padding-bottom: 1rem;
    }

    #cartPageContainer #cartContent .cartItemRow .customizationDetails{
        max-height: none ;
        width: 90%;
        font-size: calc(1.1vw + 1.1vh);
        margin: 2rem 0 0 2rem;
    }

    #cartPageContainer #cartContent .cartItemRow .cartPrice{
        height: 8%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    #cartPageContainer #cartContent .cartItemRow .cartPrice img{
        padding-left: 2rem;
    }

    #cartPageContainer #cartContent .cartItemRow .cartPrice p{
        font-size: calc(1.2vw + 1.2vh);
    }

    #cartPageContainer .checkoutBtn {
        padding-right: 1rem;
    }
    #cartPageContainer .checkoutBtn .componentOptionButton{
        font-size: calc(1.5vw + 1.5vh);
    }

    #cartPageContainer #cartContent .cartItemRow ul{
        flex-wrap: nowrap;
    }


    #cartPageContainer #cartContent .cartItemRow ul li{
        font-size: calc(1vw + 1vh);
        padding: 0.2rem 0;

    }

    .shippingForm {
        flex-direction: column;
        width: 97%;
        padding-left: 0.8rem;
    }

    .shippingForm .shippingInfoSectionContainer form{
        margin: 1rem;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .shippingForm .shippingInfoSectionContainer form label{
        width: 100%;
    }

    .shippingForm .shippingInfoSectionContainer,
    .shippingForm .shippingPricesSectionContainer{
    margin: 1rem 0rem 1rem 0;
    -webkit-box-shadow: 0px 4px 8px 2px var(--c2);
    -moz-box-shadow: 0px 4px 8px 2px var(--c2);
    box-shadow: 0px 4px 8px 4px var(--c2);
    color: var(--c5);
    width: 100%;
    }

  /* ===============  Cart Return and Missing Item ============*/

    #producOverlayContainerMIR{
        position: absolute;
    }

    .btnContainerRow {
       flex-direction: column;
       align-items: center;
    }

    #cartPageContainer .checkoutBtn{
        padding: 0rem;
        margin: 0.5rem;
    }

    #cartPageContainer .checkoutBtn .componentOptionButton{
        font-size: calc(1vw + 1.1vh);
        padding: 0rem 1rem;
        margin: 0rem;
    }

    .cartMIR p, .cartMIR ul{
        margin: 2vh 0vw;
        padding: 1rem;
    }

    .cartMIR label{
        display: block;
        width: 90vw;
    }
    
    .cartMIR input{
        display: block;
        margin: 1rem 0 1rem 8vw;
        width: 80%;
    }

    /* =========== shipping section ============= */
    #initialInvite{
        width: 80vw;
    }

    .shippingForm .shippingPricesSectionContainer .text {
        font-size: clamp(0.9rem, 0.8vw, 0.8rem);
        width: 70%;
      }
      
    .shippingForm .shippingPricesSectionContainer .price {
        width: 65%;
      }

      .shippingForm .shippingPricesSectionContainer .circle {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: var(--c3);
        margin-right: 0.5rem;
        position: relative;
      }
      
  }