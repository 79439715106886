#componentsPage{
    min-height: 63vh;
    /* background: linear-gradient(176deg, rgb(236,224,209) 0%, rgb(99,72,50) 80%, rgba(56,34,15,1) 130%); */
    /* background: linear-gradient(176deg, var(--c1) 0%, var(--c2) 40%, var(--c4) 110%); */
    /* background: linear-gradient(176deg, var(--c0) 20%, var(--c4) 100%); */
    background: var(--c1);
    box-sizing: border-box;
}

#componentsPage .bgBlurr{
    position: absolute;
    background: linear-gradient(180deg, rgba(236,224,209,0.2) 10%, rgba(32, 20, 9, 0.3) 100%);
    backdrop-filter: saturate(80%) blur(2px);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

#componentsPage .sectionTitle{
    Color: var(--c5);
    /* padding: 1rem;; */
    height: 93vh;
    position: relative;
    width: 100%;
    object-fit: cover;
    
    /* background-image:  url('../../images/K000_7.jpg'); */
    /* background-attachment: fixed;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative; */
    
}

#componentsPage .cardsSection{
    height: 93vh;
    width: 100%;
    position: absolute;
    top:7vh;
}

#componentsPage .cardsSection h1{
    Color: var(--c2);
    padding: 0 0 0 2rem;
    margin: 0;
    position: relative;
    top: 2vh
}

#componentsPage .cardsSection h2{
    color: var(--c2);
    position: relative;
    font-size: clamp(1.5rem, 3vw, 3rem);
    margin: 1rem 5rem;
    top: 4rem;
}

#componentsPage .cardsSection p{
    Color: var(--c1);
    padding: 0 0 0 2rem;
    margin: 0;
    width: 75vw;
    position: relative;
    right: -15vw;
    bottom: -55vh;
    text-align: right;
    font-size: clamp(1rem, 1.5vw, 1.3rem);
}

#componentsPage .sectionTitle h1{
    position: absolute;
    font-size: clamp(3rem, 7vw, 7rem);
}

#componentsPage .filterBar{
    position: sticky;
    top:9vh;
    left: 1vw;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    /* background-color: var(--c1); */
    padding: 0.5rem;
    opacity: 95%;
    /* -webkit-box-shadow: 0px 2px 8px 0px var(--c3);
    -moz-box-shadow: 0px 2px 8px 0px var(--c3);
    box-shadow: 0px 2px 8px 0px var(--c3); */
    font-size: 1.2rem;
    font-family: inherit;
    margin-top: 1rem;
}

#componentsPage .filterBar button{
   background-color: var(--c2);
   border: none;
   transition: all 0.5s;
   cursor: pointer;
   margin: 0 0.5rem;
   border-radius: 5%;
   padding: 1rem;
   font-size: 1.2rem;
   font-family: inherit;
   position: relative;
}

#componentsPage .filterBar button:hover,
#componentsPage .filterBar .selected {
   -webkit-box-shadow: 0px 2px 8px 0px var(--c3);
   -moz-box-shadow: 0px 2px 8px 0px var(--c3);
   box-shadow: 0px 2px 8px 0px var(--c3);
}


#componentsPage .filterBar button::before,
#componentsPage .filterBar .selected::before {
    content: "";
    position: absolute;
    left: -3px;
    bottom: 0;
    height: 0.7vh;
    width: 0;
    background-color: var(--c5);
    transition: 0.3s ease;
    border-radius: 5px;
  }
  

  #componentsPage .filterBar button:hover,
  #componentsPage .filterBar .selected {
    color: var(--c1);
    transition-delay: 0.3s;
    background: transparent;
  }
  

  #componentsPage .filterBar button:hover::before {
    width: 100%;
  }
  
  #componentsPage .filterBar button::after,
  #componentsPage .filterBar .selected::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color: var(--c3);
    transition: 0.3s ease;
    z-index: -1;
  }

  #componentsPage .filterBar button:hover::after,
  #componentsPage .filterBar .selected::after {
    height: 100%;
    transition-delay: 0.3s;
    border-radius: 3px 3px 0 0;
  }




/* #componentsPage .sectionTitle p{
    position: absolute;
    right: 5rem;
    bottom: 7rem;
    padding: 0;
    font-size: clamp(1.6rem, 2vw, 2rem);
    color: var(--c1);
    width: 50vw;
    /* background-color: rgba(56,34,15,0.72);
    -webkit-box-shadow: 0px 0px 151px 80px rgba(56,34,15,0.9);
    -moz-box-shadow: 0px 0px 151px 80px rgba(56,34,15,0.9);
    box-shadow: 0px 0px 151px 80px rgba(56,34,15,0.9); */
    /* box-shadow: inset -4px -11px 151px 80px rgba(56,34,15,0.9); */
    /* border-radius: 5%; */
/* } */

/* #componentsPage #componentSubpage_PCB{
    background-image:  url('../../images/K0000_2.jpg');
} */


 /* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){

}

/* Desktop */
@media screen and (min-width:1023px) and (max-width: 3000px) {

}

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1023px) {
    #componentsPage .sectionTitle{
        height: 94vh; 
    }
    #componentsPage .cardsSection h1{
        top: 3rem
    }
    
    #componentsPage .cardsSection h2{
        top: 3rem;
    }
    
    #componentsPage .cardsSection p{
        bottom: -35vh;
        right: -10vw;
        /* font-size: clamp(1rem, 4rem, 4rem); */
    }

    #componentsPage .filterBar{
        top: 4rem;
        left: 0vw;
        width: 90%;
        justify-content: flex-start;
        font-size: 1.2rem;
    }

    #componentsPage .filterBar button{
        padding: 1rem;
        font-size: 1.2rem;
     }
    /* #componentsPage .cardsSection{
        height: 30vh;
        width: 100%;
        position: absolute;
        top:12vh;
    } */
}


/* mobile */
@media screen and (max-width: 464px) {

    #componentsPage #componentSubpage_PCB{
        height: 70vh;
        object-fit: cover;
    }

    #componentsPage .sectionTitle{
        height: 70vh;
    }

    #componentsPage .cardsSection h1{
        left: 0;
        font-size: 3rem;
    }
    
    #componentsPage .cardsSection  h2{
        margin: 1rem 1rem 1rem 3rem;
        top: 2rem;
        left: 0;
        width: 85%;
        font-size: clamp(1.5rem, 2vw, 2rem);
    }
    
    #componentsPage .cardsSection p{
        top: 30vh;
        font-size: clamp(1rem, 1.8vw, 2rem);
        color: var(--c1);
        width: 90%;
        right: 2vw;
        text-align: justify;
        border-radius: 10px;
    }

    #componentsPage .filterBar{
        top: 7vh;
        left: 0vw;
        width: 90%;
        justify-content: flex-start;
        font-size: 1.2rem;
    }

    #componentsPage .filterBar button{
        padding: 1rem;
        font-size: 1.2rem;
     }

}