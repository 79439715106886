body {
    --c0: rgb(255,244,239);
    --c1: rgb(236,224,209);
    --c2: rgb(219,193,172);
    --c3: rgb(150,114,89);
    --c4: rgb(99,72,50);
    --c5: rgb(56,34,15);
    
}

/*====   cards   =====*/

#cardContainer, .carouselCardContainer{
    display: flex;
    justify-content: space-around; 
    align-items: flex-start; 
    flex-wrap: wrap;
    color: var(--c4);
}

/* #cardContainer article{
    display: flex;
} */


#cardContainer article, .carouselCardContainer article{
    margin: 3%;
    /* transition: all 0.2s linear 0s;
    -webkit-box-shadow: 0px 12px 16px 5px rgba(227,223,215,0.7);
    -moz-box-shadow: 0px 12px 16px 5px rgba(227,223,215,0.7);
    box-shadow: 0px 10px 14px 2px rgba(227,223,215,0.7); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    z-index: 1;
    flex: 1 0 21%;
    border-radius: 1%;
    position: relative;
    transition: all 0.5s;
}

#cardContainer article::before, .carouselCardContainer article::before{
    content: "";
    position: absolute;
    left: 10%;
    bottom: -2vh;
    height: 0.2vh;
    width: 80%;
    background-color: rgba(150,114,89,0.6);
    transition: all 0.3s;
}

#cardContainer article img{
    width: 100%;
    height: 20rem;
    object-fit: cover;
    /* -webkit-box-shadow: 0px 10px 30px 7px rgba(0,0,0,1);
    -moz-box-shadow: 0px 10px 30px 7px rgba(0,0,0,1);
    box-shadow: 0px 10px 30px 7px rgba(0,0,0,1);   */
}

#cardContainer article h1{
    font-size: calc(1vw + 1vh);
}

#cardContainer article .description{
    font-size: calc(0.7vw + 0.7vh);
    width: 90%;
    margin-bottom: 1rem;
    /* text-align: justify; */

}


.carouselCardContainer article img{
    width: 100%;
    height: 200px;
    object-fit: cover;
}

#cardContainer article:hover, .carouselCardContainer article:hover{
    cursor: pointer;
    -webkit-box-shadow: 0px 4px 8px 0px var(--c3);
    -moz-box-shadow: 0px 4px 8px 0px var(--c3);
    box-shadow: 0px 4px 8px 0px var(--c3);
}

#cardContainer article:hover::before, .carouselCardContainer article:hover::before{
    width: 0%;
}

#cardContainer article ul, .carouselCardContainer article ul{
    min-width: 12vw;
    list-style: none;
    text-decoration: none;
    text-align: left;
    /* max-height: 10vh;
    overflow: scroll; */
}

#cardContainer article .componentUL{
    width: 90%;
    flex-wrap: wrap;
    padding-left: 0.7rem;
}


#cardContainer article ul li, .carouselCardContainer article ul li{
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    transition: all 0.5s;
    margin-bottom: 0.5rem;
}

#componentsPage #cardContainer article ul li{
    justify-content: flex-start;
    font-size: 1rem;
}


#componentsPage #cardContainer article .GatewayComponentsPage ul {
    padding: 0;
}

#componentsPage #cardContainer article .GatewayComponentsPage ul li{
    justify-content: center;
    font-size: 1.2rem;
}

#cardContainer article ul img, .carouselCardContainer article ul img{
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.2rem 2.5rem 0.2rem 0.2rem;
    padding: 0.1rem 0;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}


#cardContainer h2, .carouselCardContainer h2{
    font-size: 3vw;
    margin: 10% 0;

}

#mainCardContainer p{
    font-size: 1.2vw;

}

#cardContainer article .actionBar, .carouselCardContainer article .actionBar{
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    width: 28%;
    height: 2.7rem;
    opacity: 0;
    transition: all 1s;
}

#cardContainer article:hover .actionBar, .carouselCardContainer article:hover .actionBar{
    opacity: 1;

}

#cardContainer article .actionBar img, .carouselCardContainer article .actionBar img{
    height: 2rem;
    width: 2rem;
    transition: all 0.5s;
}

#cardContainer article .actionBar img:hover, .carouselCardContainer article .actionBar img:hover{
    cursor: pointer;
    height: 2.2rem;
}

/* ======  navlink inside card ========= */
#cardContainer article .cardNavLink, .carouselCardContainer article .cardNavLink{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}



/* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){
    #cardContainer article{
        flex: 1 0 10%;
    }
  }

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {
    #cardContainer article{
        flex: 1 0 23%;
    }
  }

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1024px) {
    #cardContainer article{
        flex: 1 0 40%;
        margin: 5%;
    }

    #cardContainer article h1{
        font-size: 1.5rem;
    }

    #cardContainer article .description{
        font-size: 1rem;    
        text-align: justify;
    }

    #cardContainer article .actionBar{
        opacity: 1;
    }

  }


/* mobile */
@media screen and (max-width: 464px) {
    #cardContainer article{
        flex: 1 0 80%;
        margin: 12% 5%;

    }

    #cardContainer article h1{
        font-size: 2rem;
    }
    
    #cardContainer article .description{
        font-size: 1rem;
        text-align: justify;
    
    }
    /* #cardContainer .card ul, .carouselCardContainer .card ul{
        min-width: 12vw;
    } */
    
    #cardContainer article ul li, .carouselCardContainer article ul li{
        text-align: left;
    }
    
    #cardContainer article img{
        width: 100%;
        height: 20vh;
        object-fit: cover;
        /* -webkit-box-shadow: 0px 10px 30px 7px rgba(0,0,0,1);
        -moz-box-shadow: 0px 10px 30px 7px rgba(0,0,0,1);
        box-shadow: 0px 10px 30px 7px rgba(0,0,0,1);   */
    }

    #cardContainer article .actionBar{
        opacity: 1;
    }

    #cardContainer article::before{
        bottom: -3vh;

    }

  }

