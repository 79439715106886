#ownersPage{
    min-height: 63vh;
    /* background: linear-gradient(176deg, var(--c1) 0%, var(--c2) 40%, var(--c4) 110%); */
    background-color: var(--c1);
}

#ownersPage .sectionTitle{
    Color: var(--c1);
    padding: 1rem;
    height: 50vh;
    background-image:  url('../../images/65rgb_layer2Keymap.png');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

#ownersPage h1, #ownersPage p{
    margin: 0;
    padding: 1rem;
}

#ownersPage h2{
    font-size: clamp(2rem, 4vw, 4rem);
    margin: 1rem 0.5rem 0 2rem;
}

#ownersPage h3{
  font-size: clamp(2rem, 3vw, 3rem);
  margin: 1rem 5rem;
}

#ownersPage .innerText{
    position: absolute;
    width: 70%;
    min-height: 20vh;
    right: 10%;
    top: 50vh;
    text-align: justify;
    background: linear-gradient(176deg, rgba(236,224,209,0.8) 0%, rgba(219,193,172,0.9) 40%, var(--c4) 110%);
    color: var(--c5);
    padding: 3%;
}


#ownersPage .innerText p{
    font-size: clamp(1rem, 2vw, 2rem);
    line-height: 2.5rem;
}

#ownersPage #firstSection{
    margin-top: 20vh;
    margin-bottom: 2vw;
}


#ownersPage .section{
    color: var(--c4);
    box-sizing: border-box;
    position: relative;
    min-height: 20vh;
    padding-bottom: 5vh;
    padding-top: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#ownersPage .section::before{
    content: "";
    position: absolute;
    left: 15%;
    bottom: 0;
    height: 0.2vh;
    width: 70%;
    background-color: rgba(56,34,15,0.5);
}

/* #ownersPage .lastSection::before{
    bottom: 3vh;
} */

#ownersPage .section h1{
    font-size: clamp(2rem, 4vw, 4rem);
    color: var(--c5);
    align-self: flex-start;
    margin-left: 5rem;
}

#ownersPage .section header{
  align-self: flex-start;
  /* display: flex; */
}

#ownersPage .section p{
    color: var(--c4);
    width: 70%;
    padding: 3rem 4rem 1rem 4rem;
    text-align: justify;
    font-size: clamp(1rem, 2vw, 1.5rem);
    line-height: 2.3rem;
}

#ownersPage .section img{
  margin: 2rem 0rem;
  width: 40%;
}

#ownersPage .section .keymapc{
  width: 70%;
}


#ownersPage .section .separator{
  border-top: 0.1vh solid rgba(56,34,15,0.4);
  margin-top: 3rem;
}

#ownersPage .section .tableTitle{
  text-decoration: underline;
}

#ownersPage .section table{
  table-layout: fixed;
  width: 70%;
  padding: 1rem;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  border-collapse: collapse;
  border: 1px solid rgba(219,193,172,0.7);
  border-radius: 4px;
}

#ownersPage .section table thead th{
  text-align: left;
  padding: 0.3rem;
  background-color: rgba(219,193,172,0.7);
}

#ownersPage .section table thead th:nth-child(1) {
  width: 15%;
}

#ownersPage .section table thead th:nth-child(2) {
  width: 22%;
}

#ownersPage .section table thead th:nth-child(3) {
  width: 63%;
}

#ownersPage .section table tbody tr td{
  padding: 0.1rem 0.3rem;
}

#ownersPage .section table tbody tr:nth-child(even) {
  background-color: rgba(219,193,172,0.4);
}

#ownersPage .section ol{
  width: 70%;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
}

#ownersPage .section ol li{
  margin-bottom: 2rem;
  text-align: justify;
}

#ownersPage .section ol .bold{
  font-weight: bold;
}

   /* button */

#ownersPage .section .btn{
    margin-top: 2.5rem;
  }

  #ownersPage .section .btn a{
    font-size: clamp(1rem, calc(0.8vw + 0.8vh), 1.5rem);
    color: var(--c5);
    text-decoration: none;
    transition: all 1s;
  }
  
  #ownersPage .section .btn:hover a{
    cursor: pointer;
    color: var(--c2);
  }
  
  #ownersPage .section .btn:hover::before {
    width: 100%;
  }
  
  #ownersPage .section .btn::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color:  rgba(99,72,50,1);
    transition: 0.3s ease;
    z-index: -1;
  }

  #ownersPage .section .btn:hover::after {
    height: 100%;
    transition-delay: 0.3s;
    border-radius: 3px 3px 0 0;
  }



/* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){
    #ownersPage .AUCardMainContainer .AUCardContainer .AUCard .actButton{
        margin-left: -2vw;
    }
  }

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {
    #ownersPage .AUCardMainContainer .AUCardContainer .AUCard .actButton{
        margin-left: -4vw;
    }
  }

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1023px) {
    #ownersPage .innerText{
      height: 18vh;
      min-height: 0;
      top: 55vh
  }  

  #ownersPage p{
    margin: 0;
    padding: 0rem;
  }

  #ownersPage .section h1{
    margin-left: 1rem;
  }

  #ownersPage .section p{
    width: 90%;
    padding: 1rem;
    line-height: 1.8rem;
  }

  #ownersPage .section img{
    width: 85%;
  }

  #ownersPage .section table{
    table-layout: fixed;
    width: 85%;
    padding: 1rem;
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    border-collapse: collapse;
    border: 1px solid rgba(219,193,172,0.7);
    border-radius: 4px;
  }

  #ownersPage .section .keymapc{
    width: 90%;
  }                                                                                                   

  #ownersPage h3{
    font-size: clamp(1.3rem, 1.5rem, 1.7rem);
    margin: 1rem 5rem;
  }

  #ownersPage .section ol{
    width: 80%;
  }
  
}


/* mobile */
@media screen and (max-width: 464px) {

    #ownersPage .innerText p, #ownersPage .section p{
        line-height: 1.5rem;
    }

    #ownersPage .section h1{
        margin: 0;
        }
    
    #ownersPage .section p{
      width: 80%;
      padding-right: 0;
      padding-left: 0;
    }

    #ownersPage .section img{
      margin: 2rem 0rem;
      width: 90%;
    }

    #ownersPage .section table{
      width: 90%;
    }

    #ownersPage .section table thead th:nth-child(1) {
      width: 19%;
    }
    
    #ownersPage .section table thead th:nth-child(2) {
      width: 30%;
    }
    
    #ownersPage .section table thead th:nth-child(3) {
      width: 51%;
    }

    #ownersPage .section .separator{
      padding-left: 0;
      padding-right: 0;
    }
    
    #ownersPage .AUCardMainContainer h1{
            align-self: flex-start;
         }

    #ownersPage .AUCardMainContainer .AUCardContainer .AUCard .actButton{
        margin-left: 0;
    }
    
    #ownersPage .section .keymapc{
      width: 90%;
      height: 90px;
    }

    #ownersPage h3{
      font-size: clamp(1.3rem, 2vw, 2rem);
      margin: 0rem 2rem;
    }

    #ownersPage .section ol{
      width: 85%;
      padding-right: 1rem;
    }
    
  }