/*====   Nav   =====*/

nav {
    box-sizing: border-box;
    position: sticky;
    width: 100%;
    height: 7vh;
    top: 0;
    right: 0;
    z-index: 1002;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 1s;
    padding: 0 1rem;
    /* background: rgba(30,30,30,1); */
    background: linear-gradient(190deg, rgba(236,224,209,0.95) -20%, rgba(99,72,50,0.95) 30%, rgba(56,34,15,0.95) 60%);

}

nav .NB_LinkContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.navImgContainer{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}

#navLogoContainer{
  background-image: url("../../src/images/logoFinal_small_white.png");
}

#navCartLogo{
  background-image: url("../../src/images/Icon_Cart_C2_6701532.png");
  width: 30px;
  position: relative
}

.navImgContainer #navLogo{
  color: rgba(0,0,0,0);
  font-size: 1.5rem;
  margin-top: 20%;
}

#cartLogo{
  color: rgba(0,0,0,0);
  font-size: 1.5rem;
  position: relative;
  padding-left: 0;

}

nav .NB_LinkContainer #cartLogo img{
  height: 4vh;
  position: relative;
}

.navLink{
    color: rgb(236,224,209);
    font-size: 1.2rem;
    padding: 0 1vw;
    height: 100%;
    position: relative;
}
.navLink:link{
    text-decoration: none;
}

.navLink:hover::before{
  content: "";
  position: absolute;
  left: 20%;
  bottom: -1vh;
  height: 0.2vh;
  width: 60%;
  background-color: rgba(236,224,209,0.6);
  transition: all 0.3s;
}

#navBar .NB_LinkContainer .DDContainer{
  color: rgb(236,224,209);
  font-size: 1rem;
  padding: 0 1vw;
  height: 100%;
  min-height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

#navBar .NB_LinkContainer .DDContainerOff{
  cursor: not-allowed;
}


#navBar .NB_LinkContainer .DDContainer .DD{
  display: flex;
  visibility: hidden;
  transform: translateY(-10px);
  position: absolute;
  top: 43px;
  left: 0px;
  width: 150px;
  background: linear-gradient(190deg, rgba(236,224,209,0.95) 0%, rgba(99,72,50,0.95) 60%, rgba(56,34,15,0.95) 100%);
  flex-direction: column;
  padding: 10px;
  transition: all 0.2s;
}

#navBar .NB_LinkContainer .DDContainer:hover .DD,
#navBar .NB_LinkContainer .DDContainer:hover .languageDD{
  transform: translateY(0px);
  visibility: visible;
}

#navBar .NB_LinkContainer .DDContainer .DD .navLink:hover::before,
#navBar #navImgContainer .navLink:hover::before,
#navBar .showOnMobile .navLink:hover::before {
  width: 0;
}



#navBar .NB_LinkContainer .DDContainer .DD .navLink{
  padding: 10px;
}

#navBar .NB_LinkContainer .DDContainer .DD .navLink:hover{
  background-color: rgba(236,224,209,0.1);
  color: var(--c5);
  font-weight: bold;
}

#navBar .NB_LinkContainer .DDContainer .DD .subDD{
  display: flex;
  visibility: hidden;
  transform: translatex(-20px);
  position: absolute;
  top: 0px;
  left: 160px;
  width: 150px;
  background: linear-gradient(190deg, rgba(236,224,209,0.95) 0%, rgba(99,72,50,0.95) 60%, rgba(56,34,15,0.95) 100%);
  flex-direction: column;
  padding: 10px;
  transition: all 0.2s;
}

#navBar .NB_LinkContainer .DDContainer .DD #CommponentsDDContainer:hover .subDD{
  transform: translatex(0px);
  visibility: visible;
}

#navBar .showOnMobile .navCartCount{
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  right: -10px;
  border-radius: 50%;
  font-size: 0.8rem;
  background-color: rgba(255,0,0,0.8);
  color: white;
}

/* =========  Language and currency dropdowns ===========*/
#navBar .NB_LinkContainer .DDContainer .languageDD{
  display: flex;
  visibility: hidden;
  position: absolute;
  top: 30px;
  left: -10px;
  width: 100px;
  background: linear-gradient(190deg, rgba(236,224,209,0.95) 0%, rgba(99,72,50,0.95) 60%, rgba(56,34,15,0.95) 100%);
  flex-direction: column;
  padding: 10px;
  transition: all 0.2s;
}

#navBar .NB_LinkContainer .DDContainer .languageDD #langSubDDHover:hover .langCurrSubDD{
  transform: translatex(0px);
  visibility: visible;
}

#navBar .NB_LinkContainer .DDContainer .languageDD #currSubDDHover:hover .langCurrSubDD{
  transform: translatex(0px);
  visibility: visible;
}


#navBar .NB_LinkContainer .DDContainer .DD .langCurrSubDD{
  top: 0px;
  left: -125px;
  width: 100px;
  padding: 10px;
  color: var(--c1);
}
#navBar .NB_LinkContainer .DDContainer .DD .langCurrSubDD div{
  padding: 10px;
}

#navBar .NB_LinkContainer .DDContainer .DD .langCurrSubDD div:hover{
  background-color: rgba(236,224,209,0.1);
  color: var(--c5);
  font-weight: bold;
}


/* =========  Hamburger menu ===========*/
  
   label{
    flex-direction:column;
    width: 50px;
    height: 50px;
    cursor:pointer;
    display: none;
  }
  
  label span{
    box-sizing: border-box;
    background: var(--c4);
    border-radius:10px;
    height: 5px;
    margin: 8px 0 0 0;
    transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }
  
  
  span:nth-of-type(1){
    width:50%;
  }
  
  span:nth-of-type(2){
    width:90%;

  }
  
  
  span:nth-of-type(3){
    width:75%;

   
  }
  
  
  input[type="checkbox"]{
    display:none;
  }
  
  
  input[type="checkbox"]:checked ~ span:nth-of-type(1){
    transform-origin:bottom;
    transform:rotatez(45deg) translate(7px,0px);
  }
  
  
  input[type="checkbox"]:checked ~ span:nth-of-type(2){
    
    transform-origin:top;
    transform:rotatez(-45deg) translate(1px, 1px)

  }
  
  
  input[type="checkbox"]:checked ~ span:nth-of-type(3){
    
    transform-origin:bottom;
    width:50%;
    transform: translate(19px,-7px) rotatez(45deg);
  
  }
  


/* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){
  }

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {
  }

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1024px) {
  nav {
    height: 53px;
  }
  nav .NB_LinkContainer{
    display: none;
  }

  nav .showOnMobile{
    display: flex;
    width: 75vw;
    margin-right: 1vw;
    justify-content: flex-end;
    height: 95%;
  }

  nav{
    justify-content: space-between;
    height: 54px;

  }

  label{
    display: flex;
  }

  #navBar .NB_LinkContainer .DDContainer .navLink{
    font-size: 0.9rem;
  }

  nav .NB_LinkContainer #cartLogo img{
    height: 5vh;
  }

  .navImgContainer #navLogo{
    font-size: 2rem;
  }
  #navBar .NB_LinkContainer .DDContainer .navLink{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
  }


  #navBar .showOnMobile .navCartCount{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 8px;
    right: -10px;
  }

  }


/* mobile */
@media screen and (max-width: 464px) {
    nav .NB_LinkContainer{
        display: none;
    }

    nav .showOnMobile{
      display: flex;
      width: 35vw;
      margin-right: 4vw;
  }
    
    nav{
    justify-content: space-between;
    top: 0vh;
    background: linear-gradient(190deg, rgba(236,224,209,0.95) -10%, rgba(99,72,50,0.95) 50%, rgba(56,34,15,0.95) 70%);

    }

    label{
      display: flex;
    }

    #navBar .NB_LinkContainer .DDContainer .navLink{
      font-size: 0.9rem;
    }
    
    nav .NB_LinkContainer #cartLogo img{
      height: 5vh;
    }

    .navImgContainer #navLogo{
      font-size: 2rem;
    }

  }