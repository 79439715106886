
.SNBContainer{
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: linear-gradient(185deg, rgba(236,224,209,1) -30%, rgba(99,72,50,1) 30%, rgba(56,34,15,1) 60%);
    /* padding: 10px; */
    transition: all 0.6s;
    top:7vh;
    left: 0;
    color: var(--c2);
    transform: translateX(100vw);
    z-index: 1004;
}

.SNBContainer .SNBLinkContainer{
    position: fixed;
    height: 85%;
    width: 99%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
}

.open{
    transform: translateX(0vw);
}

.SNBContainer .navLink{
    height: 2vh;
    padding: 1rem;
    font-size: 1.2rem;
}

.SNBContainer div{
    display: flex;
}

.SNBContainer .subContainerOpen {
    visibility: visible;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 5vw;
    width: 90%;
}

.SNBContainer .subContainerOpen .navLink{
    width: 80vw;
}


.SNBContainer .subContainerClosed{
    max-height: 0;
    display: none;
    visibility: hidden; 
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 5vw;
}

.SNBContainer .navLink{
    width: 90vw;
    padding-right: 0;
}



/* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){
}

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {
}

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1024px) {
    .SNBContainer{
        top:53px
    }
}

/* mobile */
@media screen and (max-width: 464px) {

}