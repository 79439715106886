.utilitie_warningModal{
    position: fixed;
    top: 15vh;
    left: 30vw;
    background-color: var(--c4);
    color: var(--c1);
    width: 40vw;
    z-index: 1010;
    border-radius: 4px;
    transition: all 0.7s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column; 
    font-size: 1.3rem; 
    -webkit-box-shadow: 0px 0px 20px 15px rgba(20,20,20, 1);
    -moz-box-shadow: 0px 0px 20px 15px rgba(20,20,20, 1);
    box-shadow: 0px 0px 20px 15px rgba(50,50,50, 0.7);
}

.utilitie_warningModal p{
    text-wrap: wrap;
    word-break: break-word;
    padding: 0rem 1rem;
    text-align: center;
}

.modalHiddenUP{
    transform: translateY(-50vh);
}

.modalHiddenOpacity{
    opacity: 0;
}

  /* ============= Loader ================ */


.modalLoaderActive {
    background: 
      linear-gradient(90deg,var(--c4) 33%, var(--c5) 50%,var(--c4) 66%)
      var(--c2);
    background-size:300% 100%;
    animation: l1 4s infinite linear;
  }
  @keyframes l1 {
    0% {background-position: right}
  }

  /* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){

}

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {

}

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1024px) {

}


/* mobile */
@media screen and (max-width: 464px) {
.utilitie_warningModal{
    width: 90vw;
    left: 5vw;
    top: 15vh;
}

}