#AUPage{
    min-height: 63vh;
    /* background: linear-gradient(176deg, var(--c1) 0%, var(--c2) 40%, var(--c4) 110%); */
    background: var(--c1);
}

#AUPage .sectionTitle{
    Color: var(--c1);
    padding: 1rem;
    height: 75vh;
    background-image:  url('../../images/K0004_7.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

#AUPage h1, #AUPage p{
    margin: 0;
    padding: 1rem;
}

#AUPage h2{
    font-size: clamp(2rem, 4vw, 4rem);
    margin: 1rem 5rem;
}

#AUPage .innerText{
    position: relative;
    width: 70%;
    min-height: 30vh;
    left: 10%;
    top: -35vh;
    text-align: justify;
    background: linear-gradient(176deg, rgba(236,224,209,0.2) 0%, rgba(219,193,172,0.9) 40%, var(--c4) 110%);
    color: var(--c5);
    padding: 3%;
}

#AUPage .innerText p{
    font-size: clamp(1rem, 2vw, 2rem);
    line-height: 2.5rem;
}

#AUPage #firstSection{
    margin-top: -15vh;
    margin-bottom: 10vw;
}


#AUPage .section{
    color: var(--c4);
    box-sizing: border-box;
    position: relative;
    min-height: 50vh;
    padding-bottom: 5vh;
    padding-top: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#AUPage .section header{
    display: flex;
    align-self: flex-start;
}

#AUPage .section::before{
    content: "";
    position: absolute;
    left: 15%;
    bottom: 0;
    height: 0.2vh;
    width: 70%;
    background-color: rgba(56,34,15,0.5);
}

#AUPage .lastSection::before{
    bottom: 3vh;
}

#AUPage .section h1{
    font-size: clamp(2rem, 4vw, 4rem);
    color: var(--c5);
    align-self: flex-start;
    margin-left: 5rem;
}

#AUPage .section p{
    color: var(--c4);
    width: 70%;
    padding: 5rem 4rem 1rem 4rem;
    text-align: justify;
    font-size: clamp(1rem, 2vw, 1.5rem);
    line-height: 2.3rem;

}

#AUPage .bigPict img{
    width: 93%;
    height: 60vh;
    object-fit: cover;
}

#AUPage .AUCardMainContainer{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 4%;
    margin-top: -2vw;
}

#AUPage .AUCardMainContainer::before{
    bottom: 0vh;
}


#AUPage .AUCardMainContainer .AUCardContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 95%;
}


#AUPage .AUCardContainer .AUCard{
    display: flex;
    flex-direction: column;
    flex: 1 0 18%;
    background: linear-gradient(176deg, var(--c1) 0%, var(--c2) 40%, var(--c4) 110%);
    margin: 2rem;
    position: relative;
}

#AUPage .AUCardMainContainer .AUCardContainer .AUCard img{
    object-fit: cover;
    height: 40vh;
}

#AUPage .AUCardMainContainer .AUCardContainer .AUCard .cardText{
    height: 25vh;
}

#AUPage .AUCardMainContainer .AUCardContainer .AUCard .cardText p{
    font-size: clamp(1rem, 2vw, 1.5rem);
    text-align: left;
    width: 90%;
}



   /* button */


#AUPage .AUCardMainContainer .AUCardContainer .AUCard .actButton{
    left: -4vw;
    position: relative;
    bottom: 4vh;
}

   #AUPage .AUCardMainContainer .AUCardContainer .AUCard .btn {
    font-size: clamp(1rem, calc(0.8vw + 0.8vh), 1.5rem);
    max-width: 17vw;
  }
  
  #AUPage .AUCardMainContainer .AUCardContainer .AUCard .btn:hover {
    cursor: auto;
  }
  
  #AUPage .AUCardMainContainer .AUCardContainer .AUCard .btn:hover::before {
    width: 100%;
  }
  
  #AUPage .AUCardMainContainer .AUCardContainer .AUCard .btn::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color:  rgba(99,72,50,1);
    transition: 0.3s ease;
    z-index: -1;
  }

  #AUPage .section p{
    padding: 2rem 4rem 1rem 4rem;
}

  #AUPage .AUCardMainContainer .AUCardContainer .AUCard .btn:hover::after {
    height: 100%;
    transition-delay: 0.3s;
    border-radius: 3px 3px 0 0;
  }
/* //===== */

#AUPage .AUCardMainContainer h1{
   align-self: center;
   margin: 0;
}

#AUPage .AUCardContainer .AUCard p{
    padding: 1rem;
    margin: 0%;
}


/* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){
    #AUPage .AUCardMainContainer .AUCardContainer .AUCard .actButton{
        margin-left: -2vw;
    }
  }

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {
    #AUPage .AUCardMainContainer .AUCardContainer .AUCard .actButton{
        margin-left: -4vw;
    }
  }

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1025px) {

    #AUPage .sectionTitle{
        background-size: cover;
    }

    #AUPage .AUCardMainContainer .AUCardContainer{
        width: 90%;
        min-height: 57vw;
    }
    
    #AUPage #firstSection{
        margin-top: -28vh;
        margin-bottom: 10vw;
    }
    
    #AUPage .AUCardContainer .AUCard{
        margin: 1rem;
        width: 100%;
    }

    #AUPage .section h1{
        margin-left: 2rem;
    }

    #AUPage .AUCardMainContainer h1{
        align-self: flex-start;
        margin: 0;
     }

    #AUPage .section p{
        font-size: clamp(1rem, 1.3rem, 3rem);
        line-height: 2.3rem;
    }
    
    #AUPage .AUCardMainContainer .AUCardContainer .AUCard img{
        object-fit: cover;
        height: 40vh;
    }

    #AUPage .AUCardMainContainer .AUCardContainer .AUCard .cardText p{
        font-size: clamp(1rem, 1.3rem, 2rem);
    }

    #AUPage .AUCardMainContainer .AUCardContainer .AUCard .actButton{
        left: 0vw;
        position: relative;
        bottom: 4vh;
    }

    #AUPage .AUCardMainContainer .AUCardContainer .AUCard .btn {
        font-size: clamp(1rem, 1.5rem, 1.5rem);
        max-width: 27vw;
      }

    #AUPage .innerText p{
        font-size: clamp(1rem, 1.5rem, 2rem);
    }

    
  }


/* mobile */
@media screen and (max-width: 464px) {

    #AUPage .innerText p, #AUPage .section p{
        line-height: 1.5rem;
        padding-right: 0;
        padding-left: 0;
        font-size: clamp(1rem, 4vw, 3rem);
    }

    #AUPage .sectionTitle{
        background-size: cover;
    }

    #AUPage .section h1{
        margin: 0;
        }
    
    #AUPage .AUCardMainContainer h1{
            align-self: flex-start;
         }

    #AUPage .AUCardMainContainer .AUCardContainer .AUCard .actButton{
        margin-left: 0;
    }   

    #AUPage .AUCardMainContainer .AUCardContainer .AUCard img{
        object-fit: cover;
        height: 20vh;
    }

    #AUPage .AUCardMainContainer .AUCardContainer .AUCard .btn {
        max-width: 80vw;
        width: 60vw;
    }

    #AUPage .innerText{
        position: relative;
        top: -70vw;
        left: 10%;
        text-align: justify;
        background: linear-gradient(176deg, rgba(236,224,209,0.2) 0%, rgba(219,193,172,0.9) 40%, var(--c4) 110%);
        color: var(--c5);
        padding: 3%;
        margin-left: 1rem;
    }

    #AUPage #firstSection{
        margin-top: -20vh;
    }

    #AUPage .AUCardMainContainer .AUCardContainer .AUCard .cardText{
        height: 250px;
    }

    #AUPage h2{
        margin: 1rem 2rem;
    }
  }