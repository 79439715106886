/* --c0: rgb(255,244,239);
--c1: rgb(236,224,209);
--c2: rgb(219,193,172);
--c3: rgb(150,114,89);
--c4: rgb(99,72,50);
--c5: rgb(56,34,15); */


#inStockPage{
    min-height: 63vh;
    /* background: linear-gradient(176deg, rgb(236,224,209) 0%, rgb(99,72,50) 80%, rgba(56,34,15,1) 130%); */
    /* background: linear-gradient(176deg, var(--c1) 0%, var(--c2) 40%, var(--c4) 110%); */
    background: var(--c1);
    box-sizing: border-box;
}

#inStockPage .IS_SectionTitle{
    Color: var(--c1);
    padding: 1rem;
    height: 93vh;
    background-image:  url('../../images/inStock_main.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

#inStockPage .IS_SectionTitle p, #inStockPage .IS_SectionTitle h1{
    Color: var(--c1);
    padding: 0 0 0 2rem;
    margin: 0;
}

#inStockPage .IS_SectionTitle p{
    position: absolute;
    right: 5rem;
    bottom: 7rem;
    padding: 0;
    font-size: clamp(1.6rem, 2vw, 2rem);
    color: var(--c1);
    width: 50vw;
    border-radius: 5%;
}

#inStockPage .IS_SectionTitle h1{
    font-size: clamp(3rem, 7vw, 7rem);
}

#inStockPage .IS_SectionTitle h2{
    font-size: clamp(2rem, 4vw, 4rem);
    margin: 1rem 5rem;
}

#inStockPage .filterBar{
    position: sticky;
    top:9vh;
    left: 1vw;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    /* background-color: var(--c1); */
    padding: 0.5rem;
    opacity: 95%;
    /* -webkit-box-shadow: 0px 2px 8px 0px var(--c3);
    -moz-box-shadow: 0px 2px 8px 0px var(--c3);
    box-shadow: 0px 2px 8px 0px var(--c3); */
    font-size: 1.2rem;
    font-family: inherit;
    margin-top: 1rem;
}

#inStockPage .filterBar button{
   background-color: var(--c2);
   border: none;
   transition: all 0.5s;
   cursor: pointer;
   margin: 0 0.5rem;
   border-radius: 5%;
   padding: 1rem;
   font-size: 1.2rem;
   font-family: inherit;
   position: relative;
}

#inStockPage .filterBar button:hover,
#inStockPage .filterBar .selected {
   -webkit-box-shadow: 0px 2px 8px 0px var(--c3);
   -moz-box-shadow: 0px 2px 8px 0px var(--c3);
   box-shadow: 0px 2px 8px 0px var(--c3);
}


#inStockPage .filterBar button::before,
#inStockPage .filterBar .selected::before {
    content: "";
    position: absolute;
    left: -3px;
    bottom: 0;
    height: 0.7vh;
    width: 0;
    background-color: var(--c5);
    transition: 0.3s ease;
    border-radius: 5px;
  }
  

  #inStockPage .filterBar button:hover,
  #inStockPage .filterBar .selected {
    color: var(--c1);
    transition-delay: 0.3s;
    background: transparent;
  }
  

  #inStockPage .filterBar button:hover::before {
    width: 100%;
  }
  
  #inStockPage .filterBar button::after,
  #inStockPage .filterBar .selected::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color: var(--c3);
    transition: 0.3s ease;
    z-index: -1;
  }

  #inStockPage .filterBar button:hover::after,
  #inStockPage .filterBar .selected::after {
    height: 100%;
    transition-delay: 0.3s;
    border-radius: 3px 3px 0 0;
  }

  /* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){

}

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {

}

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1024px) {
    #inStockPage .IS_SectionTitle{
        height: 89vh;
    }


    #inStockPage .IS_SectionTitle p{
        right: 0rem;
        bottom: 15vh;
        font-size: clamp(1rem, 1.2rem, 2rem);
        width: 60vw;
    }

    #inStockPage .filterBar{
        top: 4rem;
        left: 0vw;
        width: 90%;
        justify-content: flex-start;
        font-size: 1.2rem;
    }

    #inStockPage .filterBar button{
        padding: 1rem;
        font-size: 1.2rem;
     }


}


/* mobile */
@media screen and (max-width: 464px) {
    #inStockPage .IS_SectionTitle p{
        right: 1rem;
        top: 190px;
        font-size: clamp(1.2rem, 2vw, 2rem);
        width: 90vw;
        background-color: rgba(56,34,15,0);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    #inStockPage .filterBar{
        top: 7vh;
        left: 0vw;
        width: 90%;
        justify-content: flex-start;
        font-size: 1.2rem;
    }

    #inStockPage .filterBar button{
        padding: 1rem;
        font-size: 1.2rem;
     }

}