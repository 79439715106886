#ourCreationPage{
    min-height: 63vh;
    /* background: linear-gradient(176deg, var(--c1) 0%, var(--c2) 40%, var(--c4) 110%); */
    box-sizing: border-box;
    background-color: var(--c1);
}

#ourCreationPage .IS_SectionTitle{
    Color: var(--c1);
    padding: 1rem;
    height: 93vh;
    background-image:  url('../../images/K0002_4.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

#ourCreationPage .IS_SectionTitle p, #ourCreationPage .IS_SectionTitle h1{
    Color: var(--c1);
    padding: 0 0 0 1rem;
    margin: 0;
}

#ourCreationPage .IS_SectionTitle p{
    position: relative;
    top: 40vh;
    right: -20vw;
    padding: 0;
    font-size: clamp(1.6rem, 2vw, 2rem);
    color: var(--c1);
    width: 70vw;
    /* background-color: rgba(56,34,15,0.72);
    -webkit-box-shadow: 0px 0px 151px 80px rgba(56,34,15,0.9);
    -moz-box-shadow: 0px 0px 151px 80px rgba(56,34,15,0.9);
    box-shadow: 0px 0px 151px 80px rgba(56,34,15,0.9); */
    /* box-shadow: inset -4px -11px 151px 80px rgba(56,34,15,0.9); */
    border-radius: 5%;
    text-align: justify;
}

#ourCreationPage .IS_SectionTitle h1{
    font-size: clamp(3rem, 7vw, 7rem);
}

#ourCreationPage .IS_SectionTitle h2{
    font-size: clamp(2rem, 4vw, 4rem);
    margin: 1rem 1rem 1rem 3rem;
}

#ourCreationPage .filterBar{
    position: sticky;
    top:9vh;
    left: 1vw;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    /* background-color: var(--c1); */
    padding: 0.5rem;
    opacity: 95%;
    /* -webkit-box-shadow: 0px 2px 8px 0px var(--c3);
    -moz-box-shadow: 0px 2px 8px 0px var(--c3);
    box-shadow: 0px 2px 8px 0px var(--c3); */
    font-size: 1.2rem;
    font-family: inherit;
    margin-top: 1rem;
}

#ourCreationPage .filterBar button{
   background-color: var(--c2);
   border: none;
   transition: all 0.5s;
   cursor: pointer;
   margin: 0 0.5rem;
   border-radius: 5%;
   padding: 1rem;
   font-size: 1.2rem;
   font-family: inherit;
   position: relative;
}

#ourCreationPage .filterBar button:hover,
#ourCreationPage .filterBar .selected {
   -webkit-box-shadow: 0px 2px 8px 0px var(--c3);
   -moz-box-shadow: 0px 2px 8px 0px var(--c3);
   box-shadow: 0px 2px 8px 0px var(--c3);
}


#ourCreationPage .filterBar button::before,
#ourCreationPage .filterBar .selected::before {
    content: "";
    position: absolute;
    left: -3px;
    bottom: 0;
    height: 0.7vh;
    width: 0;
    background-color: var(--c5);
    transition: 0.3s ease;
    border-radius: 5px;
  }
  

  #ourCreationPage .filterBar button:hover,
  #ourCreationPage .filterBar .selected {
    color: var(--c1);
    transition-delay: 0.3s;
    background: transparent;
  }
  

  #ourCreationPage .filterBar button:hover::before {
    width: 100%;
  }
  
  #ourCreationPage .filterBar button::after,
  #ourCreationPage .filterBar .selected::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color: var(--c3);
    transition: 0.3s ease;
    z-index: -1;
  }

  #ourCreationPage .filterBar button:hover::after,
  #ourCreationPage .filterBar .selected::after {
    height: 100%;
    transition-delay: 0.3s;
    border-radius: 3px 3px 0 0;
  }


  /* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){

}

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {

}

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1023px) {
    #ourCreationPage .IS_SectionTitle{
        height: 95vh;
        padding: 1rem 0 0 0;
    }

    #ourCreationPage .IS_SectionTitle p{
        position: relative;
        top: 15vh;
        font-size: clamp(1.2rem, 2vw, 1rem);
    }

    #ourCreationPage .filterBar{
        top: 4rem;
        left: 0vw;
        width: 90%;
        justify-content: flex-start;
        font-size: 1.2rem;
    }

    #ourCreationPage .filterBar button{
        padding: 1rem;
        font-size: 1.2rem;
     }

    
}


/* mobile */
@media screen and (max-width: 464px) {
    #ourCreationPage .IS_SectionTitle p{
        left: 0.5rem;
        color: var(--c1);
        font-size: clamp(1.2rem, 2vw, 2rem);
        width: 90vw;
        background-color: rgba(56,34,15,0);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    #ourCreationPage .filterBar{
        top: 7vh;
        left: 0vw;
        width: 90%;
        justify-content: flex-start;
        font-size: 1.2rem;
    }

    #ourCreationPage .filterBar button{
        padding: 1rem;
        font-size: 1.2rem;
     }


}