
/*====   product overlay   =====*/

#producOverlayContainer{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 1vh;
    left: 0vw;
    z-index: 1001;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#producOverlayContainer #producDetailsContainer{
    position: relative;
    background: linear-gradient(176deg, var(--c1) 0%, var(--c2) 77%, var(--c4) 110%);
    width: 90%;
    height: 89vh;
    top: 8vh;
    left: 5vw;;
    border-radius: 0.4rem;
    -webkit-box-shadow: 10px 10px 60px 30px rgba(0,0,0,1);
    -moz-box-shadow: 10px 10px 60px 30px rgba(0,0,0,1);
    box-shadow: 10px 10px 80px 200px rgba(47, 46, 46, 0.9);
    /* border: 2px solid rgba(255,255,255,0.7); */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center; 
    flex-wrap: wrap;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#producOverlayContainer::-webkit-scrollbar {
    display: none;
}

/* Not Working, needs to be fix !! */
#producDetailsContainer::-webkit-scrollbar {
    display: none;
}

#productDetailsCarousel{
    width: 100%;
    height: 49%;
}


#productDetailsCarousel .carousel-container{
    height: 100%;
}

#productDetailsCarousel .carousel-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#clicToOpen{
    position: absolute;
    top: 0;
    left: 10%;
    width: 80%;
    height: 49%;
    z-index: 1006;
}

#clicToOpen:hover{
    cursor: pointer;
}

#producDetailsInfo{
    width: 95%;
    height: 51%;
    /* background: pink; */
    display: flex;
    overflow-y: auto;
    justify-content: flex-start; 
    /* padding: 1rem 1rem; */
    font-size: 1.1rem;
    flex-wrap: wrap;
    position: relative;
}

/* Left bottom side container */
#producDetailsInfo ul{
    color: var(--c5);
    list-style-type: none;
    flex: 1 0 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 3rem;
    font-weight: bold;
}

/* Right bottom side container */
#productDetailsDescription{
    font-size: 1.2rem;
    padding: 0 1rem 0 1rem;
    margin: 4rem  0;
    word-wrap: break-word;
    color: var(--c5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1 0 60%;
    text-align: justify;
    text-justify: inter-word;
}

/* Detail of the left bottom side container */
#producDetailsInfo ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    transition: all 0.5s;
    margin-bottom: 1rem;

}

#producDetailsInfo ul img{
    height: 2rem;
    /* background-color: red; */
    margin: 0.2rem 2.5rem 0.2rem 0.2rem;
    padding: 0.1rem 0;

}

#producDetailsContainer #producDetailsInfo #actionBar{
    position: absolute;
    top: 2vh;
    right: 2vw;
    width: 10%;
    height: 6vh;
    display: flex;
    justify-content: space-between;
}

#producDetailsContainer #actionBar img{
    height: 3rem;
    transition: all 0.5s;
}

#producDetailsContainer #actionBar img:hover{
    cursor: pointer;
    height: 3.5rem;
}

/* =====  photo overlay ======= */

#photoOverlayContainer{
    position: fixed;
    top: 0vh;
    width: 100vw;
    height: 100vh;
    z-index: 1004;
}

#photoCarouselContainer{
    height: 100vh;
    width: 100vw;
    z-index: 1004;
}

#photoCarouselContainer .carousel-container{
    width: 100vw;
    height: 100vh;

}

#photoCarouselContainer .carousel-container img {
    width: 100vw;
    height: 101vh;
    object-fit: cover;
    z-index: 1004;
}

#photoCarouselContainer .carousel-container .react-multi-carousel-dot-list{
    bottom: 2vh;
} 

#photoActionBar{
    position: absolute;
    top: 3vh;
    right: 3vw;
    z-index: 1005;
    width: 8rem;
    height: 3.5rem;
    display: flex;
    justify-content: space-around;
    background-color: var(--c0);
    border-radius: 5%;
    padding-top: 0.5rem;
}

#photoActionBar img{
    height: 3rem;
    transition: all 0.5s;
}

#photoActionBar img:hover{
    cursor: pointer;
    height: 3.3rem;
}

/* --c0: rgb(255,244,239);
--c1: rgb(236,224,209);
--c2: rgb(219,193,172);
--c3: rgb(150,114,89);
--c4: rgb(99,72,50);
--c5: rgb(56,34,15); */






/* ============= RESPONSIVE ================ */
/* ExtraWide Monitor */
@media screen and (min-width:3000px){
  }

/* Desktop */
@media screen and (min-width:1024px) and (max-width: 3000px) {
  }

/* Tablet */
@media screen and (min-width:464px) and (max-width: 1023px) {
    #productDetailsCarousel{
        width: 100%;
        height: 60%;
    }

    #clicToOpen{
        height: 60%;
    }

    #producDetailsInfo{
        height: 40%;
    }

    #producOverlayContainer #producDetailsContainer{
        height: 83vh;
        top: 14vh;
    }

    #producDetailsContainer #producDetailsInfo #actionBar{
        right: 2vw;
        width: 16%;
    }

    #productDetailsCarousel .carousel-container img {
        object-fit: cover;
    }

    #photoCarouselContainer .carousel-container img {
        background-color: black;
        object-fit: cover;
    }

  }


/* mobile */
@media screen and (max-width: 464px) {
    #productDetailsCarousel, #clicToOpen{
        height: 45%;
    }
    
    #productDetailsCarousel .carousel-container img {
        object-fit: cover;
    }

    #productDetailsCarousel .carousel-container .react-multi-carousel-track {
        height: 100%;
    }

    #producDetailsContainer #producDetailsInfo #actionBar{
        top: 2vh;
        right: 2vw;
        width: 20vw;
    }
    
    #producDetailsContainer #actionBar img{
        height: 2rem;
        transition: all 0.5s;
    }
    #producDetailsInfo{
        height: 55%;
    }

    #producDetailsInfo ul{
        margin-top: 40px;
        padding-left: 1rem;
    }

    #producDetailsContainer #actionBar img:hover{
        height: 2rem;
    }

    #photoCarouselContainer .carousel-container img {
        background-color: black;
        object-fit: contain;
    }

  }

